/* COLORS */
body {
    --color1: #FADD22;
    --color2: #44AA33;
    --color3: #FF5511;
    --dark: #112211; 
    --light: #EEEEDD;
}

#bg1, .bg1, .light-bg { color: var(--dark); }
#bg2, #bg3, .bg2, .bg3, .dark-bg { color: var(--light); }

 /* CUSTOM CLASSES AND IDs */
._navLogo {
  height: 5rem;
  width: 5rem;
}

#_footerImg {
  margin-left: 10vw;
  margin-right: 10vw;
}

._card { width: var(--cardUnit); }
._cardImage {
  max-width: var(--cardUnit);
  max-height: var(--cardUnit);
}

._hLimit { max-height: 40vh; }

._yFade {  
  box-shadow:
  0 3rem 3rem -4.5rem var(--dark) inset,
  0 -3rem 3rem -4.5rem var(--dark) inset;
}

._hl[selected] {
  background-color: var(--light);
}

/* SCROLLBAR */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--dark) var(--light);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar { width: 14px; }
#root { margin-right: 100px; }

*::-webkit-scrollbar-track {
  background: repeating-linear-gradient(
    45deg,
    var(--color2),
    var(--color2) 1px,
    var(--color1) 2px,
    var(--color1) 3px,
    var(--color2) 4px
  );
}

*::-webkit-scrollbar-thumb { border: 8px outset var(--color1); }

#_desktopCard { 
  display: flex;
  opacity: 0;
}
#_desktopCard:hover,
#_desktopCard:focus,
#_desktopCard:active { 
  opacity: 1;
}

#_mobileCard {
  display: none;
}

._outerTop {
  display: flex;
  top: -10%;
  min-height: 2.5rem;
  align-items: center;
}
._outerBottom {
  bottom: -10%;
}

#_centerFix {
  height: 100%;
  width: 100%;
}

._valid {
  border: 4px outset limegreen;
  background-color: lightgreen;
}
._notValid {
  border: 4px outset coral;
  background-color: lightcoral;
}

/* RESPONSIVENESS */
@media (max-width: 5000px) {
  #_desktopFooter { display: flex; }
  #_mobileFooter { display: none; }
  ._fw { width: 20rem; }
  body { --cardUnit: 16rem; }
  ._itemName { font-size: 1.5rem; }
  ._itemPrice { font-size: 2rem; }
}
@media (max-width: 1200px) {
  ._fw { width: 12rem; }
  ._fw2 { width: 20rem; }
  body { --cardUnit: 14rem; }
}
@media (max-width: 900px) {
  #_desktopFooter { display: none; }
  #_mobileFooter { display: flex; }
  ._fw { 
    height: fit-content;
    overflow: hidden;
  }
  ._fw2 { width: 20rem; }
  body { --cardUnit: 12rem; }
  ._itemName { font-size: 1rem; }
  ._itemPrice { font-size: 1.5rem; }
  ._scaleWhenMobile { font-size: 1.2rem; }
}
@media (max-width: 600px) {
  body { --cardUnit: 10rem; }
  ._itemPrice { font-size: 1.2rem; }
  ._scaleWhenMobile { font-size: 1.4rem; }
  #_desktopCard { display: none; }
  #_mobileCard { display: flex; }
  div[css2] {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
